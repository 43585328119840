:root {
  --primary: #23366c;
}
.thumbsContainer {
  margin-top: 25px;
}

.thumb {
  display: inline-flex;
  border-radius: 4px;
  border: 1px dashed var(--primary);
  margin-bottom: 20px;
  margin-right: 20px;
  height: 160px;
  max-width: 320px;
  padding: 10px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  align-items: center;
  min-width: 0;
  overflow: hidden;
}

.img {
  display: block;
  max-width: 100%;
  height: 100%;
}

.spinner {
  text-align: center;
  align-items: center;
  margin: auto;
  width: 5%;
}
