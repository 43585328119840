$primary: #23366c;
$primary_hover: #002aa3;
$primary_focus: #5f99ff;
$primary_blue: #0041d9;
$light_blue: #8b95b1;
$card_hover: #7a87a7;
$light_blue1: #e6edf9;
$white: #ffffff;
$black: #000000;
$color_f4f5: #f4f5f8;
$color_e7e7: #e7e7e7;
$body_color: #f7f8fc;
$placeholder_color: #c1c7d7;
$light_warning: #fff9e1;
$green: #4d9335;
$light_green: #8cbf59;
$light_success: #26d5ce;
$light_success_hover: #1eaaa4;
$select_hover: #e8eaf0;
$dark_layout: #20334e;
$pink: #fb306e;
$pink_hover: #c1043e;
$old_mint: #77c99c;
$disable_btn: #c1c7d7;
$error-color: #ff7959;
$gloabal_width: 1440px;
$big_gloabal_width: 1920px;

$mediaSizes: (
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1500px,
  'xxxl': 1950px,
  'maxxl': 10000px
);

@mixin span_16 {
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 18px;
    line-height: 28px;
  }
}

@mixin span_14 {
  color: $primary;
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 16px;
    line-height: 25px;
  }
}

@mixin linebreak2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.create-tab-section {
  .card.create-card {
    border-radius: 4px;
    border: 0;
    .card-body.create-body {
      padding: 6px 18px 32px 18px;
      ul.nav.nav-pills {
        position: relative;
        @media (max-width: map-get($mediaSizes, 'md')) {
          display: inline-block;
          width: 100%;
        }
        &::before {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: $body_color;
          z-index: 1;
        }
        li {
          @media (max-width: map-get($mediaSizes, 'md')) {
            display: inline-block;
          }
        }
        .count-div {
          position: absolute;
          right: 6px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          height: fit-content;
          font-size: 21px;
          line-height: 26px;
          font-weight: 400;
          color: $primary;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            font-size: 15px;
            line-height: 18px;
          }
          @media (max-width: map-get($mediaSizes, 'md')) {
            top: unset;
            bottom: -30px;
          }
        }
        a {
          cursor: pointer;
          padding: 21px 16px;
          border-radius: 0;
          border-bottom: 2px solid $body_color;
          color: $color_e7e7;
          font-size: 15px;
          line-height: 26px;
          font-weight: 600;
          z-index: 2;
          position: relative;
          cursor: text;
          text-transform: uppercase !important;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            font-size: 15px;
            line-height: 18px;
            padding: 10px 10px;
          }
          &.nav-link.active {
            color: $primary;
            background-color: transparent;
            border-bottom: 2px solid $primary;
          }
          &.nav-link.success {
            color: $green !important;
            background-color: transparent;
            border-bottom: 2px solid $green !important;
          }
        }
      }
      .tab-content {
        padding: 31px 18px 0px 18px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          padding: 40px 15px 0px 15px;
        }
        .tab-field-data {
          margin-right: auto;
          .form-group {
            margin-bottom: 33px;
            &.mb23 {
              margin-bottom: 23px;
            }
            .form-label {
              color: $light_blue;
              margin-bottom: 13px;
            }
          }
        }
        .text-field-data {
          max-width: 376px;
          margin-right: auto;
          margin-bottom: 30px;
          &.tab2 {
            max-width: 500px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            color: $primary;
            font-weight: 400;
          }
        }
        .button-tab-bottom {
          text-align: center;
          text-transform: uppercase;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            margin-top: 30px;
          }
          .btn-white {
            min-height: 43px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 12px !important;
            padding: 7px 10px !important;
            color: $primary;
            text-transform: uppercase;
            &:hover {
              background-color: $primary !important;
              color: $white !important;
            }
            @media (max-width: map-get($mediaSizes, 'lg')) {
              padding: 8px 5px !important;
            }
          }
          .btn-success {
            margin-left: 10px;
            text-transform: uppercase;
            @media (max-width: map-get($mediaSizes, 'lg')) {
              padding: 8px 10px !important;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
