@font-face {
  font-family: 'Mulish';
  src: url('../../assets/fonts/Muli-Regular.ttf?isafyd') format('truetype'),
    url('../../assets/fonts/Muli-Regular.woff?isafyd') format('woff'),
    url('../../assets/fonts/Muli-Regular.woff2?isafyd') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assets/fonts/Muli-SemiBold.ttf?isafyd') format('truetype'),
    url('../../assets/fonts/Muli-SemiBold.woff?isafyd') format('woff'),
    url('../../assets/fonts/Muli-SemiBold.woff2?isafyd') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assets/fonts/Muli-Bold.ttf?isafyd') format('truetype'),
    url('../../assets/fonts/Muli-Bold.woff?isafyd') format('woff'),
    url('../../assets/fonts/Muli-Bold.woff2?isafyd') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assets/fonts/Muli-ExtraBold.ttf?isafyd') format('truetype'),
    url('../../assets/fonts/Muli-ExtraBold.woff?isafyd') format('woff'),
    url('../../assets/fonts/Muli-ExtraBold.woff2?isafyd') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'dihkbo-icons';
  src: url('../../assets/fonts/dihkbo-icons.ttf?dfwgl3') format('truetype'),
    url('../../assets/fonts/dihkbo-icons.woff?dfwgl3') format('woff'),
    url('../../assets/fonts/dihkbo-icons.svg?dfwgl3#dihkbo-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
