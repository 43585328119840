$primary: #23366c;
$primary_hover: #002aa3;
$primary_focus: #5f99ff;
$primary_blue: #0041d9;
$light_blue: #8b95b1;
$card_hover: #7a87a7;
$light_blue1: #e6edf9;
$white: #ffffff;
$black: #000000;
$color_f4f5: #f4f5f8;
$color_e7e7: #e7e7e7;
$body_color: #f7f8fc;
$placeholder_color: #c1c7d7;
$light_warning: #fff9e1;
$green: #4d9335;
$light_green: #8cbf59;
$light_success: #26d5ce;
$light_success_hover: #1eaaa4;
$select_hover: #e8eaf0;
$dark_layout: #20334e;
$pink: #fb306e;
$pink_hover: #c1043e;
$old_mint: #77c99c;
$disable_btn: #c1c7d7;
$error-color: #ff7959;
$cuColorF1F2: #f1f2f7;
$cuColorE8: #e8f2de;
$danger2: #e74c3c;
$gloabal_width: 1440px;
$big_gloabal_width: 1920px;

$mediaSizes: (
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1500px,
  'xxxl': 1950px,
  'maxxl': 10000px
);

// font style
@import 'styles/sass/fonts';
@import 'styles/sass/spaces';
@import 'styles/sass/icons';

body {
  font-family: 'Mulish', sans-serif !important;
  background-color: $body_color !important;
}
.common-container {
  margin: 0 auto;
  padding: 0 !important;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    max-width: $gloabal_width !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    max-width: $big_gloabal_width;
  }
}

a {
  text-decoration: none !important;
}

.default-color {
  color: $primary;
}

/***************************
* Default
****************************/
.pull-right {
  text-align: right;
}

// mixins

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@mixin all_center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
@mixin linebreak1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
@mixin linebreak2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
@mixin scroll_horizontal {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $body_color;
  }
  &::-webkit-scrollbar {
    height: 0.375rem;
    background-color: $body_color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($placeholder_color, 1);
  }
}
@mixin scroll_vertical {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $body_color;
  }

  &::-webkit-scrollbar {
    width: 0.375rem;
    background-color: $body_color;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($placeholder_color, 1);
  }
}

//common-class
.span-10 {
  font-size: 10px;
  line-height: 12.55px;
  color: $light_blue;
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 10px !important;
    line-height: 12.55px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 14px;
    line-height: 20px;
  }
}
@mixin span_14 {
  color: $primary;
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 16px;
    line-height: 25px;
  }
}
.span-14 {
  @include span_14();
}
@mixin span_16 {
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 18px;
    line-height: 28px;
  }
}
.span-16 {
  @include span_16();
}

.form-label {
  color: $light_blue;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 14px !important;
    line-height: 40px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 16px;
    line-height: 21px;
  }
}

.center-items-with-space {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.pointer {
  cursor: pointer;
}
.fw300 {
  font-weight: 300 !important;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.fw800 {
  font-weight: 800 !important;
}
.fw900 {
  font-weight: 900 !important;
}

.line-break-1 {
  @include linebreak1();
}
.line-break-2 {
  @include linebreak2();
}
input[type='search']::-webkit-search-cancel-button {
  cursor: pointer;
}
.form-control,
input,
input.form-control {
  min-height: 48px;
  border-radius: 8px !important;
  border: 1px solid $primary;
  color: $primary !important;
  font-size: 14px;
  line-height: 21px;
  padding: 14px 12px;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &.flatpickr-input {
    &:disabled,
    &[readonly] {
      background: $white !important;
    }
  }
}
.error-msg {
  font-weight: 400;
  color: $error-color;
  margin: 0px 12px;
  display: block;
  margin-top: 2px;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 10px !important;
    line-height: 13px;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 14px;
    line-height: 14px;
  }
}
textarea.form-control {
  padding: 14px 12px;
}
input.form-control,
textarea.form-control {
  font-size: 14px;
  line-height: 17.37px;
  color: $primary;
  font-weight: 400;
  &:focus {
    color: $primary_hover;
  }
  &::placeholder {
    color: $primary;
  }
  &::-webkit-input-placeholder {
    color: $primary;
  }
  &::-moz-placeholder {
    color: $primary;
  }
  &:-ms-input-placeholder {
    color: $primary;
  }
  &:-moz-placeholder {
    color: $primary;
  }
}
//
.form-group {
  &.error-gives {
    .floating-label {
      color: $error-color !important;
    }
    input,
    textarea {
      border-color: $error-color !important;
    }
    textarea::placeholder,
    input::placeholder {
      color: $error-color !important;
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: $error-color !important;
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
      color: $error-color !important;
    }
    input:-ms-input-placeholder,
    textarea::-moz-placeholder {
      color: $error-color !important;
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: $error-color !important;
    }
    //select__control
    .select__control {
      border-color: $error-color !important;
      background: url('./assets/image/icon-select-arrow-error.svg') no-repeat $white;
      background-position: right 21px center;
    }
  }
}
.floating-label-group {
  position: relative;
  &.dark {
    input:focus ~ .floating-label,
    input:not(:placeholder-shown) ~ .floating-label,
    textarea:focus ~ .floating-label,
    textarea:not(:placeholder-shown) ~ .floating-label {
      color: $white;
      background: transparent !important;
      top: -17px;
    }
  }
  .floating-label {
    color: $placeholder_color;
    margin: 0px 11px;
    position: absolute;
    pointer-events: none;
    top: 15px;
    left: 1px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    text-transform: capitalize;
  }

  input:focus ~ .floating-label,
  input:not(:placeholder-shown) ~ .floating-label,
  textarea:focus ~ .floating-label,
  textarea:not(:placeholder-shown) ~ .floating-label {
    bottom: 0px;
    left: 0px;
    opacity: 1;
    padding: 0px 2px 0px 1px;
    color: $primary;
    background: $white;
    height: fit-content;
    @media (max-width: map-get($mediaSizes, 'sm')) {
      margin: -10px 11px;
      font-size: 0.64rem !important;
      top: -8px !important;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 0.64rem !important;
      top: -8px !important;
    }
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      font-size: 0.8rem;
      top: -10px;
    }
  }
  textarea:focus::placeholder,
  input:focus::placeholder {
    opacity: 0;
  }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    opacity: 0;
  }
  input:focus:-ms-input-placeholder,
  textarea:focus::-moz-placeholder {
    opacity: 0;
  }
  input:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder {
    opacity: 0;
  }
}

.btn {
  transition: 0.5s ease-in-out !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.btn-cu-xl {
  min-width: 200px;
}
.btn-cu-lg {
  min-width: 147px;
}
.btn-cu-md {
  min-width: 110px;
}
.btn-cu-sm {
  min-width: 80px;
}
.react-icon-48 {
  border-radius: 50%;
}
.react-12-cut {
  border-radius: 12px 12px 0px 12px;
}
.react-12 {
  border-radius: 12px;
}
.react-16 {
  border-radius: 16px;
}
.react-20 {
  border-radius: 20px;
}
.react-icon-48,
.react-12-cut,
.react-12,
.react-16,
.react-20 {
  &.disabled {
    cursor: not-allowed;
  }
}
.btn {
  &.disabled,
  &:disabled {
    background: $disable_btn !important;
    background-color: $disable_btn !important;
    color: $white !important;
    border-color: $disable_btn !important;
    cursor: not-allowed !important;
  }
}
.btn-icon {
  width: 48px;
  height: 48px;
  padding: 0 !important;
  @include all_center();
  @media (max-width: map-get($mediaSizes, 'md')) {
    width: 40px;
    height: 40px;
    min-height: 40px !important;
    padding: 5px !important;
  }
  &.btn-40 {
    width: 40px;
    height: 40px;
    min-height: 40px !important;
  }
  &.btn-primary {
    background: $primary_blue;
    border-color: $primary_blue;
    color: $white;
    border-radius: 50% !important;
  }
}
.btn-white {
  background: $white !important;
}
.btn-primary {
  background: $primary_blue !important;
  border-color: $primary_blue !important;
  color: $white !important;
  min-height: 48px;
  border-radius: 20px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    background: $primary_hover !important;
    border-color: $primary_hover !important;
  }
  &:focus {
    background: $primary_focus !important;
    border-color: $primary_focus !important;
  }
  span {
    font-size: 14px;
    margin: 0;
  }
  img {
    width: 13px;
    margin: 9px 4px;
  }
}
.btn-success {
  background: $light_green !important;
  border-color: $light_green !important;
  color: $white !important;
  min-height: 43px;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 12px 12px 0px 12px !important;
  padding: 7px 10px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    background: $green !important;
    border-color: $green !important;
  }
}
.btn-light-success {
  background: $light_success !important;
  border-color: $light_success !important;
  color: $white !important;
  height: 48px;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 16px !important;
  padding: 9px 33px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    background: $light_success_hover !important;
    border-color: $light_success_hover !important;
  }
}
.btn-pink {
  background: $pink !important;
  border-color: $pink !important;
  color: $white !important;
  height: 48px;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
  padding: 6px 24px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    background: $pink_hover !important;
    border-color: $pink_hover !important;
  }
}
.title {
  h3 {
    font-size: 18px;
    line-height: 23px;
    color: $primary;
    font-weight: 400;
    margin-bottom: 0;
    &.mb23 {
      margin-bottom: 23px;
    }
  }
}
#root {
  position: relative;
  .with-box {
    &.side-visible {
      transition: 0.3s ease-in-out;
      @media (max-width: map-get($mediaSizes, 'md')) {
        .mid-section {
          width: 100% !important;
          left: 0 !important;
          transition: 0.3s ease-in-out;
        }
      }
    }
    .mid-section {
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: calc(100% - 72px);
        transition: 0.3s ease-in-out;
      }
    }
  }
  .with-box .mid-section {
    &.bulk-container {
      height: calc(100vh - 130px) !important;
    }
    position: absolute;
    top: 72px;
    left: 72px;
    width: calc(100% - 72px);
    height: calc(100vh - 72px);
    z-index: 99;
    padding: 26px 47px 26px 35px;
    background-color: $body_color !important;
    overflow-x: auto;
    @include scroll_vertical();
    @media (max-width: map-get($mediaSizes, 'md')) {
      top: 66px;
      height: calc(100vh - 66px);
      padding: 26px 20px 26px 20px;
    }
    @media (max-width: map-get($mediaSizes, 'sm')) {
      padding: 26px 15px 26px 15px;
    }
  }
}
// header
header.header {
  .navbar {
    padding: 0px 24px 0px 18px;
    background-color: $white;
    box-shadow: rgba($black, 0.1) 0px 0px 4px;
    @media (max-width: map-get($mediaSizes, 'md')) {
      padding: 10px 18px 10px 18px;
    }
    &.navbar-expand-md {
      flex-wrap: nowrap;
      justify-content: flex-start;
      .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        .navbar-nav {
          flex-direction: row;
        }
      }
    }
    .navbar-toggler {
      border: 0;
      padding: 0;
      display: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .react-select {
      min-width: 115px;
    }
    .container-fluid {
      padding: 0;
      @media (max-width: map-get($mediaSizes, 'xxl')) {
        max-width: $gloabal_width !important;
      }
      @media (max-width: map-get($mediaSizes, 'maxxl')) {
        max-width: $big_gloabal_width;
      }
      .navbar-brand {
        padding: 0;
        margin-right: 0;
        @media (max-width: map-get($mediaSizes, 'md')) {
          margin-left: 50px;
        }
        img {
          max-width: 252px;
          margin-right: 32px;
          @media (max-width: map-get($mediaSizes, 'sm')) {
          }
          @media all and (max-width: 430px) {
            max-width: 200px;
            margin-right: 0px;
          }
          @media all and (max-width: 360px) {
            max-width: 160px;
          }
        }
      }
      ul {
        align-items: center;
        li {
          margin-right: 12px;
          @media (max-width: map-get($mediaSizes, 'sm')) {
            &:first-child {
              display: none;
            }
          }
          a.btn-link {
            width: 48px;
            height: 48px;
            padding: 0 !important;
            @include all_center;
            border-radius: 16px;
            @media (max-width: map-get($mediaSizes, 'md')) {
              width: 35px;
              height: 35px;
            }
          }
          a.nav-link {
            padding: 0 !important;
            &:hover {
              background-color: $color_f4f5;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .dropdown {
          .dropdown-toggle {
            position: relative;
            &::after {
              position: absolute;
              right: 23px;
              top: 0;
              bottom: 0;
              margin: auto 0;
              height: 6px;
              border-top: 6px solid $primary;
              border-right: 5px solid transparent;
              border-bottom: 0;
              border-left: 5px solid transparent;
              @media (max-width: map-get($mediaSizes, 'md')) {
                right: 5px;
              }
            }
          }
          .drop-main {
            align-items: center;
            display: grid;
            grid-auto-flow: column;
            gap: 16px;
            max-width: 288px;
            padding: 12px 52px 12px 16px;
            width: fit-content;
            cursor: pointer;
            margin: 0px 0px 0px auto;
            @media (max-width: map-get($mediaSizes, 'md')) {
              padding: 8px 20px 8px 15px;
            }
            .drop-one {
              width: 48px;
              height: 48px;
              line-height: 23px;
              @include all_center();
              text-align: center;
              @media (max-width: map-get($mediaSizes, 'md')) {
                width: 30px;
                height: 30px;
                line-height: 14px;
              }
              div {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                color: $white;
                background-color: $primary;
                font-size: 18px;
                font-weight: 700;
                @include all_center();
                @media (max-width: map-get($mediaSizes, 'md')) {
                  font-size: 14px;
                }
              }
            }
            .drop-two {
              display: grid;
              @media (max-width: map-get($mediaSizes, 'md')) {
                display: none;
              }
              p {
                font-size: 14px;
                line-height: 18px;
                color: $primary;
                margin-bottom: 5px;
                text-transform: lowercase;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 144px;
              }
              span {
                display: block;
                font-size: 10px;
                line-height: 13px;
                color: $light_blue;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                white-space: nowrap;
                max-width: 144px;
              }
            }
          }
          .dropdown-menu {
            padding: 0;
            border: 0;
            border-radius: 0px 0px 8px 8px;
            box-shadow: 0px 0px 8px rgba($black, 0.1);
            overflow-y: auto;
            @include scroll_vertical();
            width: 100%;
            @media (max-width: map-get($mediaSizes, 'md')) {
              position: absolute;
              right: 0;
            }
            .dropdown-item {
              padding: 6px 16px;
              min-height: 64px;
              font-size: 10px;
              line-height: 52px;
              text-transform: uppercase;
              text-align: center;
              color: $primary;
              @media (max-width: map-get($mediaSizes, 'md')) {
                min-height: 40px;
                line-height: 28px;
              }
              &:hover,
              &:focus,
              &:active,
              &.active {
                background: $cuColorF1F2 !important;
              }
            }
          }
        }
      }
    }
  }
}

// sidebar
.sidebar-left {
  background-color: $white;
  min-height: calc(100vh - 72px);
  box-shadow: rgba($black, 0.1) 0px 0px 4px;
  padding: 16px 12px;
  width: 72px;
  z-index: 4;
  @media (max-width: map-get($mediaSizes, 'md')) {
    position: fixed;
    top: 65px;
    left: 0;
    height: calc(100vh - 65px);
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
    @include scroll_vertical();
    transition: 0.3s ease-in-out;
    box-shadow: 5px 0px 6px -4px rgba(0, 0, 0, 0.29);
    &.active {
      left: -90px;
      .btn-check-sidebar {
        z-index: 999;
      }
    }
  }
  .btn-check-sidebar {
    display: none;
    top: 13px;
    @media (max-width: map-get($mediaSizes, 'md')) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      position: fixed;
      left: 16px;
      top: 16px;
      z-index: 999;
      padding: 5px;
      border-radius: 2px !important;
      min-height: 35px;
      width: 35px;
      border: 1px solid $primary;
      background: $white;
      img {
        width: 25px;
      }
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li {
      display: block;
      margin-bottom: 16px;
      .side-data {
        display: inline-flex;
        align-items: center;
        border-radius: 16px;
        transition: 0.3s ease-in-out;
        position: relative;
        z-index: 999;
        .icons-data {
          width: 48px;
          height: 48px;
          border-radius: 16px;
          @include all_center();
          transition: 0.3s ease-in-out;
        }
        .text-data {
          width: calc(100% - 48px);
          display: none;
          padding-right: 16px;
          font-size: 10px;
          color: $primary;
          font-weight: 800;
          line-height: 21px;
          transition: 0.3s ease-in-out;
          white-space: pre;
          text-transform: uppercase;
          @media (max-width: map-get($mediaSizes, 'md')) {
            display: none;
          }
        }
      }
      a {
        text-decoration: none;
        transition: 0.3s ease-in-out;
        text-transform: capitalize;
        &.active {
          &:hover {
            .side-data {
              background: $primary_blue;
              .icons-data {
                img {
                  filter: brightness(0) invert(1);
                }
              }
              .text-data {
                display: block;
                color: $white;
                @media (max-width: map-get($mediaSizes, 'md')) {
                  display: none;
                }
              }
            }
          }
          .side-data {
            .icons-data {
              background: $primary_blue;
              img {
                filter: brightness(0) invert(1);
              }
            }
          }
        }
        &:hover {
          .side-data {
            background: $light_blue1;
            .text-data {
              display: block;
              @media (max-width: map-get($mediaSizes, 'md')) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// loader
.loader {
  height: 100vh;
  width: 100%;
  z-index: 99999999;
  position: fixed;
  background: rgba($black, 0.3);
  .loader-data {
    height: 100vh;
    width: 100%;
    @include all_center();

    .spinner {
      width: 100px;
      height: 100px;
      animation: spin 1.5s infinite;
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: 80px;
        height: 80px;
      }
    }
  }
}

// bread-title
.bread-title {
  margin-bottom: 29px;
  h1 {
    font-size: 21px;
    line-height: 26.39px;
    color: $primary;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.search-right {
  &.search-data {
    @media (max-width: map-get($mediaSizes, 'lg')) {
      display: block;
      width: 100%;
      margin-bottom: 0px;
    }
    .input-group {
      border-radius: 100px;
      overflow: hidden;
      min-width: 381px;
      border: 1px solid transparent;
      transition: 0.3s ease-in-out;
      &:hover {
        border: 1px solid rgb(232, 234, 240);
      }
      &.shadows {
        border: 1px solid rgb(232, 234, 240);
        box-shadow: 0px 0px 8px rgba($black, 0.1);
      }
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: 100%;
        min-width: unset;
      }
      input {
        border: 0 !important;
        padding: 11px 0px 11px 24px;
        &:focus ~ .input-group {
          box-shadow: 0px 1px 10px 0px rgba($black, 0.1);
        }
        &::placeholder {
          color: $placeholder_color;
        }
        &::-webkit-input-placeholder {
          color: $placeholder_color;
        }
        &::-moz-placeholder {
          color: $placeholder_color;
        }
        &:-ms-input-placeholder {
          color: $placeholder_color;
        }
        &:-moz-placeholder {
          color: $placeholder_color;
        }
      }
      .btn-white {
        padding: 9px 16px 13px;
      }
    }
  }
}

// search-filter-data
.search-filter-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
  @media (max-width: map-get($mediaSizes, 'lg')) {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .search-data {
    @media (max-width: map-get($mediaSizes, 'lg')) {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }
    .input-group {
      border-radius: 100px;
      overflow: hidden;
      min-width: 381px;
      border: 1px solid transparent;
      transition: 0.3s ease-in-out;
      &:hover {
        border: 1px solid rgb(232, 234, 240);
      }
      &.shadows {
        border: 1px solid rgb(232, 234, 240);
        box-shadow: 0px 0px 8px rgba($black, 0.1);
      }
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: 100%;
        min-width: unset;
      }
      input {
        border: 0 !important;
        padding: 11px 0px 11px 24px;
        &:focus ~ .input-group {
          box-shadow: 0px 1px 10px 0px rgba($black, 0.1);
        }
        &::placeholder {
          color: $placeholder_color;
        }
        &::-webkit-input-placeholder {
          color: $placeholder_color;
        }
        &::-moz-placeholder {
          color: $placeholder_color;
        }
        &:-ms-input-placeholder {
          color: $placeholder_color;
        }
        &:-moz-placeholder {
          color: $placeholder_color;
        }
      }
      .btn-white {
        padding: 9px 16px 13px;
      }
    }
  }
  .filter-data {
    @media (max-width: map-get($mediaSizes, 'lg')) {
      display: block;
      width: 100%;
    }
    ul {
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      li {
        display: inline-block;
        margin-right: 24px;
        vertical-align: middle;
        &:last-child {
          margin-right: 0;
        }
        @media (max-width: map-get($mediaSizes, 'lg')) {
          &:first-child {
            width: calc(100% - 72px);
          }
        }
        @media (max-width: map-get($mediaSizes, 'md')) {
          &:first-child {
            width: calc(100% - 64px);
          }
        }
      }
    }
  }
}

// filter-box
.filter-box {
  position: relative;
  &.max {
    .btn-white {
      span {
        max-width: 200px;
        text-align: left;
        line-height: 1.2;
        @include linebreak1();
      }
    }
  }
  .btn-white {
    height: 48px;
    border-radius: 8px;
    padding: 12px 19px 12px 16px;
    @include all_center();
    font-size: 12px;
    line-height: 21;
    font-weight: 400;
    color: $primary;
    img {
      margin-left: 24px;
    }
  }
  .main-filter-box {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 0;
    height: 0;
    border-radius: 8px;
    background-color: $white;
    padding: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    &.show {
      height: auto;
      width: 320px;
      transition: 0.3s ease-in-out;
      opacity: 1;
      visibility: visible;
      padding: 21px 16px 29px 16px;
      @media (max-width: map-get($mediaSizes, 'md')) {
        right: unset;
        left: 0;
        width: 320px !important;
      }
      @media all and (max-width: 425px) {
        width: 215px !important;
      }
    }
    @media (max-width: map-get($mediaSizes, 'md')) {
      right: unset;
      left: 0;
    }
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .one {
        font-size: 12px;
        line-height: 21px;
        font-weight: 400;
        color: $primary;
      }
    }
    .data-filter {
      position: relative;
      .select__single-value {
        margin-right: 15px;
      }
      .closer {
        position: absolute;
        right: 45px;
        bottom: 13px;
        img {
          width: 13px;
        }
      }
      p {
        margin-bottom: 15px;
        text-transform: uppercase;
      }
      .select__control {
        background: url('./assets/image/icon-filter.svg') no-repeat $white;
        background-position: right 16px center;
      }
    }
  }
}
// select react

.select__menu-portal {
  z-index: 99999 !important;
  .select__menu {
    border-radius: 2px;
    .select__option {
      padding: 10px 16px;
      font-size: 14px;
      line-height: 21px;
      min-height: 40px;
      color: $primary;
      &:hover,
      &:active,
      &.select__option--is-selected {
        background: $select_hover;
        background-color: $select_hover;
      }
    }
  }
}
.with-search {
  .select__control {
    min-height: 48px;
    height: auto;
  }
  .select__input-container {
    margin: 0 2px !important;
    .select__input {
      border: 0 !important;
      min-height: 26px !important;
    }
  }
}
.height-set {
  .select__control {
    min-height: 48px;
    height: auto;
  }
  .select__input-container {
    margin: 0 5px !important;
    .select__input {
      border: 0 !important;
      min-height: 26px !important;
    }
  }
  .select__placeholder {
    margin-left: 4px;
  }
  .select__multi-value {
    margin: 3px 6px 3px 0px;
  }
  .select__multi-value {
    background-color: rgba($primary, 1);
    .select__multi-value__label {
      color: $white;
    }
    .select__multi-value__remove {
      &:hover {
        background-color: rgba($primary, 1);
      }
      svg {
        color: $white;
      }
    }
  }
}
.select__control {
  height: 48px;
  border-radius: 8px !important;
  border-color: $primary !important;
  background: url('./assets/image/icon-select-arrow.svg') no-repeat $white;
  background-position: right 21px center;
  cursor: pointer !important;
  &.select__control--is-focused {
    box-shadow: none !important;
  }
  .select__value-container {
    padding: 0 10px;
  }
  .select__indicator-separator {
    display: none;
  }
  .select__indicator.select__dropdown-indicator {
    opacity: 0;
  }
  .select__placeholder {
    color: $primary;
    font-size: 14px;
    line-height: 20px;
  }
  .select__single-value {
    font-size: 14px;
    color: $primary;
  }
}
.select__menu {
  border-radius: 8px;
  .select__menu-list {
    max-height: 196px;
    overflow-y: auto;
    padding: 0 !important;
    @include scroll_vertical();
  }
  .select__option {
    padding: 13.2px 16px;
    font-size: 14px;
    line-height: 21px;
    min-height: 40px;
    color: $primary;
    &:hover,
    &:active,
    &.select__option--is-selected {
      background: $select_hover;
      background-color: $select_hover;
    }
  }
}

// search-filter-data
.badges-card-data {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 38px;
  row-gap: 38px;
  @media (max-width: map-get($mediaSizes, 'xl')) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: map-get($mediaSizes, 'lg')) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: map-get($mediaSizes, 'md')) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px !important;
    row-gap: 20px !important;
  }
  .card {
    border: 0;
    border-radius: 8px;
    text-decoration: none;
    transition: 0.3s ease-in;
    &:hover {
      transition: max-height 0.5s ease-in, background-color 0.5s ease-in,
        background-color 0.5s ease-in;
      background-color: $card_hover !important;
      .card-body {
        .date-div {
          p.chamber,
          span {
            color: $white;
          }
        }
        // .date-div {
        //   .name {
        //     p.chamber {
        //       overflow: unset;
        //       text-overflow: unset;
        //       white-space: unset;
        //     }
        //   }
        // }
        .card-text-data {
          span,
          span.mix,
          h4,
          p {
            color: $white;
          }
        }
      }
    }
    .card-body {
      padding: 20px 21px 15px 17px;
      .date-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .date {
          width: 67px;
          span {
            display: block;
          }
        }
        .name {
          width: calc(100% - 67px);
          padding-left: 10px;
          text-align: right;
          text-transform: uppercase;
        }
        p.chamber {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        span,
        p.chamber {
          font-weight: 400;
          color: $light_blue;
          margin-bottom: 0;
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            font-size: 10px !important;
            line-height: 12.55px !important;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      .card-img {
        text-align: center;
        margin: 25px 0 19px;
        img {
          max-width: 164px;
          max-height: 92px;
          min-height: 92px;
          margin: 0 auto;
          @media (max-width: map-get($mediaSizes, 'md')) {
            max-width: 100% !important;
          }
        }
      }
      .card-text-data {
        text-align: center;
        h4 {
          font-weight: 700;
          color: $primary;
          margin-bottom: 0;
          text-decoration: none;
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            @include span_16();
            margin-bottom: 0px;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 5px;
          }
        }
        p {
          font-weight: 400;
          color: $primary;
          max-width: 184px;
          margin: 0 auto 16px;
          min-height: 36px;
          @include linebreak2();
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            font-size: 10px;
            line-height: 15px;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        span.mix {
          display: inline-block;
          font-weight: 400;
          color: $primary;
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            font-size: 12px;
            line-height: 15px;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            @include span_14();
          }
          h3 {
            font-size: 16px;
            line-height: 25.1px;
            font-weight: 700;
            margin-right: 2px;
            margin-bottom: 0;
            display: inline-block;
          }
        }
      }
    }
  }
}

/**************************
* Order Badges
***************************/
section.order-badges {
  padding: 26px 35px 26px 35px !important;
  @media (max-width: map-get($mediaSizes, 'md')) {
    padding: 26px 20px !important;
  }
  @media (max-width: map-get($mediaSizes, 'sm')) {
    padding: 26px 15px !important;
  }
  .block-container {
    background-color: $white;
    width: 100%;
    min-height: calc(100vh - 125px);
    @include all_center();
    @media (max-width: map-get($mediaSizes, 'lg')) {
      padding: 40px 40px;
    }
    @media (max-width: map-get($mediaSizes, 'sm')) {
      padding: 20px 20px;
    }
  }
  h1.title {
    color: $primary;
    font-weight: 700;
    max-width: 55%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 20px !important;
      line-height: 1.5em !important;
      max-width: 100% !important;
    }
    @media (max-width: map-get($mediaSizes, 'lg')) {
      max-width: 90%;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 23px;
      line-height: 1.4em;
    }
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      font-size: 23px;
      line-height: 1.4em;
    }
  }
  p.description {
    color: $primary;
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 15px !important;
      line-height: 1.5em !important;
      max-width: 100% !important;
    }
    @media (max-width: map-get($mediaSizes, 'lg')) {
      max-width: 90%;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 16px;
      line-height: 1.4em;
    }
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      font-size: 16px;
      line-height: 1.4em;
    }
  }
  img.order-badge {
    margin-bottom: 50px;
    max-height: 250px;
    @media (max-width: map-get($mediaSizes, 'lg')) {
      margin-bottom: 30px;
    }
  }
}

/* notfound-section */
section.notfound-section.mid-section.new-section {
  padding: 26px 35px 26px 35px !important;
  @media (max-width: map-get($mediaSizes, 'md')) {
    padding: 26px 20px !important;
  }
  @media (max-width: map-get($mediaSizes, 'sm')) {
    padding: 26px 15px !important;
  }
  .notfound-data {
    background-color: $white;
    width: 100%;
    min-height: calc(100vh - 125px);
    padding: 96px 48px;
    @include all_center();
    @media (max-width: map-get($mediaSizes, 'lg')) {
      padding: 40px 40px;
    }
    @media (max-width: map-get($mediaSizes, 'sm')) {
      padding: 20px 20px;
    }
  }
  h1 {
    color: $primary;
    font-weight: 700;
    max-width: 55%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 20px !important;
      line-height: 1.5em !important;
      max-width: 100% !important;
    }
    @media (max-width: map-get($mediaSizes, 'lg')) {
      max-width: 90%;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 23px;
      line-height: 1.4em;
    }
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      font-size: 23px;
      line-height: 1.4em;
    }
  }
  img.notfound {
    margin-bottom: 88px;
    max-height: 338px;
    @media (max-width: map-get($mediaSizes, 'lg')) {
      margin-bottom: 30px;
    }
  }
}

/* pagenotfound-section */
section.pagenotfound-section {
  @include all_center();
  h1 {
    color: $primary;
    font-weight: 700;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 6rem !important;
      line-height: 8rem !important;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 8rem;
      line-height: 9rem;
    }
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      font-size: 10rem;
      line-height: 11rem;
    }
  }
  .page-title {
    margin-bottom: 20px;
    font-weight: 400;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 18px !important;
      line-height: 25px !important;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  .page-sub-title {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  p {
    margin-bottom: 2rem;
    text-transform: unset !important;
  }
}

/* noavailablebadges-section */
section.noavailablebadges-section {
  @include all_center();
  h1 {
    color: $dark_layout;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 40px 0 15px;
  }
  p {
    color: $dark_layout;
    margin-bottom: 1rem;
    text-transform: unset !important;
  }

  a {
    color: $primary;
  }
}

// arrow-data
.arrow-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  width: 100%;
  @media (max-width: map-get($mediaSizes, 'md')) {
    display: block;
    margin-bottom: 20px;
  }
  &.big-control {
    @media (max-width: map-get($mediaSizes, 'lg')) {
      width: 100%;
      display: block;
    }
    .left-data {
      width: calc(100% - 575px);
      padding-right: 15px;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
      }
    }
    .right-data {
      width: 575px;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        width: 100%;
      }
      @media (max-width: map-get($mediaSizes, 'md')) {
        ul {
          width: 100%;
          text-align: right;
          li:nth-child(1) {
            width: 100%;
            text-align: left;
            display: block;
            padding-right: 0;
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  &.single {
    display: flex;
    @media (max-width: map-get($mediaSizes, 'md')) {
      margin-bottom: 0;
    }
    @media (max-width: map-get($mediaSizes, 'sm')) {
      display: block;
      margin-bottom: 20px;
    }
    .left-data {
      width: calc(100% - 48px);
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: calc(100% - 48px);
        display: inline-block;
        margin-bottom: 0;
        padding-right: 16px;
      }
      @media (max-width: map-get($mediaSizes, 'sm')) {
        display: block;
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
      }
    }
    .right-data {
      width: 48px;
      @media (max-width: map-get($mediaSizes, 'sm')) {
        display: block;
        width: 100%;
        padding-right: 0;
      }
    }
  }
  .left-data {
    width: calc(100% - 180px);
    padding-right: 16px;
    @media (max-width: map-get($mediaSizes, 'md')) {
      display: block;
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }
    .head-back-data {
      display: inline-block;
      width: 100%;
      margin-bottom: 9px;
      .back-data {
        width: 24px;
        display: inline-block;
        vertical-align: middle;
      }
      .head-data {
        width: calc(100% - 24px);
        display: inline-block;
        vertical-align: middle;
        h3 {
          color: $primary;
          font-weight: 400;
          margin-bottom: 0;
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            font-size: 20px;
            line-height: 25px;
          }
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  .right-data {
    width: 180px;
    @media (max-width: map-get($mediaSizes, 'md')) {
      display: block;
      width: 100%;
      padding-right: 0;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      margin-left: auto;
      width: fit-content;
      li {
        display: inline-block;
        margin-right: 16px;
        vertical-align: middle;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

// common-modal
.common-modal {
  &.bigs {
    @media (min-width: map-get($mediaSizes, 'xs')) {
      &.modal-dialog {
        width: 500px;
        max-width: calc(100% - 30px);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @media (min-width: map-get($mediaSizes, 'xs')) {
    &.modal-dialog {
      width: 450px;
      max-width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  .modal-content {
    box-shadow: 0px 3px 16px 0px rgba($black, 0.16);
    border: 0;
    border-radius: 8px;
  }
  .modal-body {
    position: relative;
    padding: 25px 25px 30px 25px;
    .close-modal {
      position: absolute;
      top: 29px;
      right: 29px;
    }
    .icon-modal {
      margin-bottom: 32px;
      .icon-warning {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $light_warning;
        margin: 0 auto;
        @include all_center();
      }
    }
    h3.modal-title {
      margin-bottom: 16px;
    }
    p.modal-text {
      margin-bottom: 18px;
    }
    p.mb-32 {
      margin-bottom: 32px;
    }
    .react-12-cut {
      margin: 0 auto;
    }
    .modal-big-btn {
      margin: 0 auto;
      border-radius: 12px 12px 0px 12px !important;
      @include all_center();
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600 !important;
    }
  }
  &.preview-modal {
    .modal-content {
      box-shadow: none;
      border: 0;
      border-radius: 4px;
    }
    @media (min-width: map-get($mediaSizes, 'xs')) {
      &.modal-dialog {
        width: 964px;
        max-width: calc(100% - 30px);
        margin-left: auto;
        margin-right: auto;
      }
      .modal-body {
        padding: 70px 25px;
        .close-modal {
          top: 25px;
          right: 25px;
        }
      }

      .table-responsive {
        width: 100%;
        @include scroll_horizontal();
        table {
          width: 100%;
          margin-bottom: 0;
          thead {
            th {
              padding: 15px 24px;
              color: $light_blue;
              font-weight: 400;
              text-transform: uppercase;
              @media (max-width: map-get($mediaSizes, 'maxxl')) {
                font-size: 14px;
                line-height: 18px;
              }
              @media (max-width: map-get($mediaSizes, 'xxl')) {
                font-size: 10px;
                line-height: 12.55px;
              }
              &:nth-child(1) {
                max-width: 200px;
                width: 200px;
                min-width: 200px;
              }
              &:nth-child(2) {
                max-width: 220px;
                width: 220px;
                min-width: 220px;
              }
              &:nth-child(3) {
                max-width: 200px;
                width: 200px;
                min-width: 200px;
                text-align: center;
              }
              &:nth-child(4) {
                max-width: 160px;
                min-width: 160px;
                text-align: center;
              }
            }
          }
          tbody {
            > tr:hover > * {
              --bs-table-accent-bg: $white !important;
            }
            tr {
              border-radius: 8px;
            }
            td {
              padding: 24px 24px;
              vertical-align: middle;
              &:nth-child(3),
              &:nth-child(4) {
                text-align: center;
              }
              a:hover {
                text-decoration: underline;
              }
              a,
              span {
                color: $primary;
                font-weight: 400;
                letter-spacing: 0.22px;
                text-decoration: none;
                @media (max-width: map-get($mediaSizes, 'maxxl')) {
                  @include span_16();
                }
                @media (max-width: map-get($mediaSizes, 'xxl')) {
                  font-size: 14px !important;
                  line-height: 18px !important;
                }
              }
              &:first-child {
                border-radius: 8px 0 0 8px;
              }
              &:last-child {
                border-radius: 0 8px 8px 0;
                padding-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}

// edit-tab-section
.edit-tab-section {
  .card.edit-card {
    border-radius: 4px;
    border: 0;
    .card-body.edit-body {
      padding: 6px 18px 32px 18px;
      ul.nav.nav-pills {
        position: relative;
        @media (max-width: map-get($mediaSizes, 'md')) {
          display: inline-block;
          width: 100%;
        }
        &::before {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: $body_color;
          z-index: 1;
        }
        li {
          @media (max-width: map-get($mediaSizes, 'md')) {
            display: inline-block;
          }
        }
        .count-div {
          position: absolute;
          right: 6px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          height: fit-content;
          font-size: 21px;
          line-height: 26px;
          font-weight: 400;
          color: $primary;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            font-size: 15px;
            line-height: 18px;
          }
          @media (max-width: map-get($mediaSizes, 'md')) {
            top: unset;
            bottom: -30px;
          }
        }
        a {
          cursor: pointer;
          padding: 21px 16px;
          border-radius: 0;
          border-bottom: 2px solid $body_color;
          color: $color_e7e7;
          font-size: 15px;
          line-height: 26px;
          font-weight: 600;
          z-index: 2;
          position: relative;
          cursor: text;
          text-transform: uppercase !important;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            font-size: 15px;
            line-height: 18px;
            padding: 10px 10px;
          }
          &.nav-link.active {
            color: $primary;
            background-color: transparent;
            border-bottom: 2px solid $primary;
          }
          &.nav-link.success {
            color: $green !important;
            background-color: transparent;
            border-bottom: 2px solid $green !important;
          }
        }
      }
      .tab-content {
        padding: 31px 18px 0px 18px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          padding: 40px 15px 0px 15px;
        }
        .tab-field-data {
          max-width: 504px;
          margin-right: auto;
          .form-group {
            margin-bottom: 33px;
            &.mb23 {
              margin-bottom: 23px;
            }
            .form-label {
              color: $light_blue;
              margin-bottom: 13px;
            }
          }
        }
        .text-field-data {
          max-width: 376px;
          margin-right: auto;
          &.tab2 {
            max-width: 349px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            color: $primary;
            font-weight: 400;
          }
        }
        .button-tab-bottom {
          text-align: right;
          text-transform: uppercase;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            margin-top: 30px;
          }
          .btn-white {
            min-height: 43px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 12px !important;
            padding: 7px 10px !important;
            color: $primary;
            text-transform: uppercase;
            &:hover {
              background-color: $primary !important;
              color: $white !important;
            }
            @media (max-width: map-get($mediaSizes, 'lg')) {
              padding: 8px 5px !important;
            }
          }
          .btn-success {
            margin-left: 10px;
            @media (max-width: map-get($mediaSizes, 'lg')) {
              padding: 8px 10px !important;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

// award-card-data

.award-card-data {
  .nav-pills {
    display: flex;
    justify-content: center;
    .nav-item {
      .nav-link {
        color: #b2b2b2;
        background-color: transparent;
        cursor: pointer;
        &.active {
          color: #23366c;
          border-bottom: 2px solid #23366c;
        }
      }
    }
  }
  .card {
    border: 0;
    border-radius: 4px;
    .card-header {
      background-color: $white;
      padding: 27px 38px 22px;
      font-size: 21px;
      line-height: 26px;
      color: $primary;
      font-weight: 400;
      margin-bottom: 0;
      border-radius: 4px 4px 0 0;
      border-bottom: 0;
      position: relative;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        font-size: 18px;
        line-height: 22px;
        padding: 15px 18px 15px;
      }
      &::before {
        position: absolute;
        content: '';
        bottom: -2px;
        width: calc(100% - 36px);
        height: 2px;
        left: 18px;
        max-width: 1073px;
        background-color: $body_color;
      }
    }
    .card-body {
      padding: 20px;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        padding: 30px 20px 20px 20px;
      }
      .award-text-data {
        max-width: 502px;
        margin: 0 auto;
        .title {
          margin-bottom: 43px;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            margin-bottom: 20px;
          }
        }
      }
      .award-csv-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        .dropzone {
          max-width: 499px;
        }
        @media (max-width: map-get($mediaSizes, 'lg')) {
          margin-top: 30px;
        }
        margin-right: auto;
        .title {
          margin-bottom: 30px;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            margin-bottom: 20px;
          }
        }
        .file-preview {
          padding: 26px 27px 21px 23px;
          width: 100%;
          max-width: fit-content;
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: 1px dashed $primary;
          position: relative;
          .preview {
            position: absolute;
            top: 11px;
            right: 9px;
            z-index: 2;
            cursor: pointer;
          }
          .one {
            width: 48px;
            display: flex;
          }
          .two {
            width: calc(100% - 48px);
            padding-left: 14px;
            display: inline-block;
            .span-14 {
              color: $light_blue;
            }
          }
        }
        .sample-file {
          margin-top: 20.5px;
          a {
            color: $primary;
            padding: 5px 0;
            &.download {
              display: flex;
              img {
                margin-right: 10px;
              }
            }
          }
        }
      }
      .form-group {
        margin-bottom: 25px;
        .form-label {
          margin-bottom: 13px;
        }
      }

      .button-tab-bottom {
        display: flex;
        justify-content: right;
        text-transform: uppercase;
        padding-top: 18px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          margin-top: 30px;
          padding-top: 0;
        }

        .btn-white {
          min-height: 43px;
          font-size: 14px;
          font-weight: 600;
          border-radius: 12px !important;
          padding: 10px 10px !important;
          color: $primary;
          &:hover {
            background-color: $primary !important;
            color: $white !important;
          }
          @media (max-width: map-get($mediaSizes, 'lg')) {
            padding: 8px 10px !important;
          }
        }
        .btn-success {
          margin-left: 10px;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            padding: 8px 10px !important;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .button-tab-bottom {
    .btn-white {
      text-transform: uppercase;
    }
  }
}
//dropzone-inner start
.dropzone-inner {
  padding: 60px 30px;
  text-align: center;
  border-radius: 8px;
  border: 1px dashed $primary;
  background: $white;
  width: calc(100% - 1px);
  margin: 0 auto;
  @media (max-width: map-get($mediaSizes, 'md')) {
    padding: 40px 20px;
  }
  cursor: pointer;
  img {
    margin-bottom: 10px;
  }
  p {
    max-width: 291px;
    margin-bottom: 0;
    margin: 0 auto;
    font-size: 14px;
    line-height: 18px;
    color: $primary;
    font-weight: 400;
  }
}
.thumb {
  position: relative;
  .btn-delete {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    padding: 0 !important;
    padding-right: 1px !important;
    right: -12.5px;
    top: -12.5px;
    @include all_center();
    img {
      width: 12px;
      transform: rotate(45deg);
    }
  }
}
//dropzone-inner end

// modal-big
.modal-dialog-scrollable {
  .modal-body {
    @include scroll_vertical();
  }
}
.modal-backdrop.show {
  &.op-8 {
    background: $dark_layout;
    opacity: 0.8 !important;
  }
  &.op-35 {
    background: $dark_layout;
    opacity: 0.35 !important;
  }
}
.modal-big {
  @media (min-width: map-get($mediaSizes, 'xs')) {
    &.modal-dialog {
      width: 1163px;
      max-width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.with-small {
    @media (min-width: map-get($mediaSizes, 'xs')) {
      &.modal-dialog {
        width: 769px;
      }
    }
    .modal-body {
      .modal-buttons {
        margin-bottom: 72px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .modal-content {
    box-shadow: 0px 3px 16px 0px rgba($black, 0.16);
    border: 0;
    border-radius: 12px;
  }
  .modal-body {
    position: relative;
    background: $dark_layout;
    border: 1px solid $white;
    padding: 33px 47px 36px 47px;
    border-radius: 12px;
    @media (max-width: map-get($mediaSizes, 'sm')) {
      padding: 40px 20px 36px 20px;
    }
    .close-modal {
      position: absolute;
      top: 29px;
      right: 29px;
      height: 16px;
      width: 16px;
      img {
        filter: brightness(0) invert(1);
      }
      @media (max-width: map-get($mediaSizes, 'sm')) {
        top: 10px;
        right: 20px;
      }
    }
    h3.modal-title {
      margin-bottom: 7px;
      color: $white;
    }
    p.modal-text {
      margin-bottom: 37px;
      color: $white;
    }
    .react-12-cut {
      margin: 0 auto;
    }
    .modal-big-btn {
      min-width: 200px;
      margin: 0 auto;
      border-radius: 12px 12px 0px 12px !important;
      @include all_center();
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600 !important;
      .btn {
        border-radius: 20px;
      }
    }
    .modal-buttons {
      margin-bottom: 50px;
      @media (max-width: map-get($mediaSizes, 'md')) {
        margin-bottom: 30px;
      }
      ul {
        margin: 0 auto;
        padding-left: 0;
        list-style: none;
        width: fit-content;
        li {
          display: inline-block;
          margin-right: 15px;
          @media (max-width: map-get($mediaSizes, 'md')) {
            display: block;
            margin-right: 0;
            margin-bottom: 15px;
            text-align: center;
            &:last-child {
              margin-bottom: 0;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          .btn {
            min-height: 32px;
            padding: 5px 26px !important;
            border-radius: 100px;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            color: $white;
            text-transform: uppercase;
            cursor: text;
            &.btn-mint {
              background-color: $old_mint;
              &.novalid {
                background-color: $pink;
              }
            }
          }
        }
      }
    }
    .grid-body {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 21px;
      row-gap: 16px;
      margin-bottom: 9px;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: map-get($mediaSizes, 'md')) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 0px;
      }
      .card {
        max-width: 342px;
        border-radius: 8px;
        border: 0;
        margin-bottom: 21px;
        background-color: $dark_layout;
        border: 1px solid $white;
        @media (max-width: map-get($mediaSizes, 'md')) {
          max-width: 100%;
        }
        &.min-317 {
          min-height: 322px;
        }
        &.novalid {
          border-color: $pink;
          .card-body {
            padding: 37px 25px 33px 25px !important;
            p {
              color: $pink;
            }
          }
        }
        &.light-card {
          background-color: $light_blue1;
          .card-body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .one {
              padding-right: 16px;
            }
            span {
              color: $light_blue !important;
            }
            p {
              color: $primary !important;
            }
          }
        }
        .card-body {
          padding: 23px 25px 23px 25px;
          .span-10 {
            margin-bottom: 8px;
            text-transform: uppercase;
            color: $white;
          }
          p {
            @include span_16();
            color: $white;
            margin-bottom: 0px;
          }
          a.text {
            @include span_14();
            font-weight: 600 !important;
            color: $white !important;
            margin-bottom: 0px;
            transition: 0.3s ease-in-out;
            &:hover {
              color: $pink;
            }
          }
          .img-data {
            text-align: center;
            padding-top: 32px;
            padding-bottom: 24px;
          }
        }
      }
    }
  }
}

// flatpickr-calendar
.flatpickr-calendar {
  &.arrowBottom:before,
  &.arrowBottom:after {
    border-top-color: $light_blue !important;
  }
  &.arrowTop:before,
  &.arrowTop::after {
    border-bottom-color: $light_blue !important;
  }
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: $primary;
  }
  .flatpickr-day {
    color: $primary;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    background-color: $light_blue;
    background: $light_blue;
    border-color: $light_blue;
    color: $white;
  }
  .flatpickr-months .flatpickr-month,
  .flatpickr-current-month .flatpickr-monthDropdown-months,
  .flatpickr-weekdays,
  span.flatpickr-weekday {
    background-color: $light_blue;
    background: $light_blue;
    border-color: $light_blue;
    color: $white;
  }
  .flatpickr-day.today {
    border-color: $light_blue;
    &:hover,
    &:focus {
      background-color: $light_blue;
      background: $light_blue;
      border-color: $light_blue;
      color: $white;
    }
  }
  .flatpickr-current-month input.cur-year {
    min-height: unset;
    border: 0 !important;
    color: $white !important;
  }
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: $light_blue;
    border-color: $light_blue;
    color: $white;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background: $light_blue;
    color: $white;
    font-size: 14px;
  }
}

// .Toastify
.Toastify__toast-container {
  padding: 0 !important;
  top: 38px;
  right: 20px;
  width: fit-content !important;
  max-width: 800px;
}
.Toastify__toast-theme--light {
  border-radius: 4px !important;
  box-shadow: none !important;
  &.Toastify__toast--success {
    background: $cuColorE8;
    align-items: center;
    padding: 15px;
  }
  &.Toastify__toast--error {
    background: $danger2;
    align-items: center;
    padding: 15px;
    .Toastify__toast-icon {
      img {
        filter: brightness(0) invert(1);
      }
    }
    .Toastify__toast-body {
      div {
        color: $white;
      }
    }
    .Toastify__close-button--light {
      color: $white;
    }
  }
  .Toastify__close-button--light {
    opacity: 1;
    color: $primary;
  }
  .Toastify__toast-body {
    padding: 0;
    padding-right: 15px;
    .Toastify__toast-icon {
      width: 17px;
      height: 17px;
      margin-right: 11px;
    }
    div {
      color: $primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .Toastify__close-button {
    align-self: unset;
  }
}
.data-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 300px);
  flex-direction: column;
  h2 {
    color: $primary;
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 30px;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 25px;
    }
    @media (max-width: map-get($mediaSizes, 'sm')) {
      font-size: 20px;
    }
  }
}
.dropzone-img-view {
  margin-bottom: 26px;
  .dropzone-inner {
    padding: 15px 18px !important;
    img {
      max-height: 151px;
      min-height: 151px;
      margin-bottom: 0 !important;
      @media (max-width: map-get($mediaSizes, 'sm')) {
        min-height: unset;
      }
    }
  }
  .thumb {
    position: relative;
    .btn-delete {
      position: absolute;
      padding-right: 0px !important;
      right: -24px;
      top: -24px;
      border-radius: 24px !important;
      background-color: $primary_blue;
      border-color: $primary_blue;
      width: 48px;
      height: 48px;
      padding: 0 !important;
      @include all_center();
      &:hover {
        background: $primary_hover !important;
        border-color: $primary_hover !important;
      }
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: 40px;
        height: 40px;
        min-height: 40px !important;
        padding: 5px !important;
        right: -20px;
        top: -20px;
      }
      img {
        width: 20px;
        transform: rotate(0deg) !important;
        @media (max-width: map-get($mediaSizes, 'md')) {
          width: 16px;
        }
      }
    }
  }
}
iframe {
  display: none;
}

.file-data-set-main {
  display: inline-flex;
  align-items: flex-start;
  @media (max-width: map-get($mediaSizes, 'xl')) {
    display: block;
  }
  @media (max-width: map-get($mediaSizes, 'lg')) {
    display: inline-flex;
  }
  @media (max-width: map-get($mediaSizes, 'md')) {
    display: block;
  }
  .file-left {
    width: 225px;
    display: inline-block;
    @media (max-width: map-get($mediaSizes, 'xl')) {
      display: block;
      width: 100%;
    }
    @media (max-width: map-get($mediaSizes, 'lg')) {
      width: 225px;
      display: inline-block;
    }
    @media (max-width: map-get($mediaSizes, 'md')) {
      display: block;
      width: 100%;
    }
  }
  .file-right {
    width: calc(100% - 225px);
    display: inline-block;
    padding-left: 20px;
    margin-top: -33px;
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      padding-left: 5px;
    }
    @media (max-width: map-get($mediaSizes, 'xl')) {
      display: block;
      width: 100%;
      padding-left: 0;
      margin-top: 15px;
    }
    @media (max-width: map-get($mediaSizes, 'lg')) {
      width: calc(100% - 225px);
      display: inline-block;
      padding-left: 10px;
      margin-top: -33px;
    }
    @media (max-width: map-get($mediaSizes, 'md')) {
      display: block;
      width: 100%;
      padding-left: 0;
      margin-top: 15px;
    }
    .btn-primary {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
    .span-list {
      color: $primary;
      font-weight: 400;
      display: block;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .alert {
      padding: 6px 10px;
      font-size: 14px;
      line-height: 18px;
      display: inline-flex;
      align-items: flex-start;
      &.alert-danger {
        color: $white;
        background-color: $pink;
        border-color: $pink;
      }
      &.alert-primary {
        color: $white;
        background-color: rgba($primary, 0.9);
        border-color: rgba($primary, 0.9);
      }
      svg {
        margin-right: 10px;
        margin-top: 2px;
      }
    }
  }
}
// Ripple color btn css

.color-light-blue {
  s {
    background-color: $light_blue1 !important;
  }
}

.color-off-white {
  s {
    background-color: $body_color !important;
  }
}

/*********************************
* Search Box
**********************************/

.search-box {
  @media (max-width: map-get($mediaSizes, 'lg')) {
    display: block;
    width: 100%;
    margin-bottom: 0px;
  }
  .input-group {
    border-radius: 100px;
    overflow: hidden;
    max-width: 450px;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;
    &:hover {
      border: 1px solid rgb(232, 234, 240);
    }
    &.shadows {
      border: 1px solid rgb(232, 234, 240);
      box-shadow: 0px 0px 8px rgba($black, 0.1);
    }
    @media (max-width: map-get($mediaSizes, 'md')) {
      width: 100%;
      min-width: unset;
    }
    input {
      border: 0 !important;
      padding: 11px 0px 11px 24px;
      &:focus ~ .input-group {
        box-shadow: 0px 1px 10px 0px rgba($black, 0.1);
      }
      &::placeholder {
        color: $placeholder_color;
      }
      &::-webkit-input-placeholder {
        color: $placeholder_color;
      }
      &::-moz-placeholder {
        color: $placeholder_color;
      }
      &:-ms-input-placeholder {
        color: $placeholder_color;
      }
      &:-moz-placeholder {
        color: $placeholder_color;
      }
    }
  }
}

/*********************************
* Breadcrumb
**********************************/

.breadcrumb-section {
  .back-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: map-get($mediaSizes, 'sm')) {
      flex-direction: column;
      text-align: center;
    }
    .back-icon {
      flex: 1;
      display: inline-block;
      width: 100%;
      margin-bottom: 9px;
      .icon {
        width: 24px;
        display: inline-block;
        vertical-align: middle;
      }
      .title {
        width: calc(100% - 24px);
        display: inline-block;
        vertical-align: middle;
        h3 {
          color: $primary;
          font-weight: 400;
          margin-bottom: 0;
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            font-size: 20px;
            line-height: 25px;
          }
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }

    .status-card {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 8px;
      padding: 4px 10px;
      min-width: 250px;
      @media (max-width: map-get($mediaSizes, 'sm')) {
        margin: 15px 0;
      }

      .status-label {
        font-size: 10px;
        color: #888;
        text-transform: uppercase;
        margin-bottom: 8px;
        text-align: left;
        @media (max-width: map-get($mediaSizes, 'sm')) {
          text-align: center;
        }
      }

      .badge-details {
        display: flex;
        justify-content: space-between;

        .badge-item {
          display: flex;
          align-items: center;

          .count {
            font-size: 20px;
            font-weight: bold;
            color: #003366;
            margin-right: 4px;
          }
          label {
            font-size: 12px;
            margin-top: 4px;
            color: #003366;
          }
        }
      }
    }
    .account-numbers {
      flex-basis: 15%;
      text-align: right;
      @media (max-width: map-get($mediaSizes, 'sm')) {
        text-align: center;
      }
      p {
        font-size: 10px;
        color: #8b95b1;
        margin: 0;
        span {
          text-transform: uppercase;
          margin-right: 20px;
        }
      }
    }
  }
  .search-action-buttons-section {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr auto auto;

    @media (max-width: map-get($mediaSizes, 'sm')) {
      grid-template-columns: auto;
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .react-ripples {
        margin-left: 10px;
      }
      @media (max-width: map-get($mediaSizes, 'sm')) {
        justify-content: center;
      }
    }
  }
}

/*********************************
* Badges Details
**********************************/

.badges-details-section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    max-width: 1500px;
  }
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    max-width: 1172px;
  }
  @media (max-width: map-get($mediaSizes, 'md')) {
    display: block;
  }
  &.table-full {
    display: block;
    max-width: 100%;
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      margin-top: 20px;
    }
    .table-responsive {
      width: 100%;
      @include scroll_horizontal();
      table {
        width: 100%;
        thead {
          th {
            padding: 15px 24px;
            color: $light_blue;
            font-weight: 400;
            text-transform: uppercase;
            @media (max-width: map-get($mediaSizes, 'maxxl')) {
              font-size: 14px;
              line-height: 18px;
            }
            @media (max-width: map-get($mediaSizes, 'xxl')) {
              font-size: 10px;
              line-height: 12.55px;
            }
            &:nth-child(1) {
              @media (max-width: map-get($mediaSizes, 'xxl')) {
                max-width: 200px;
                width: 200px;
                min-width: 200px;
              }
              @media (max-width: map-get($mediaSizes, 'maxxl')) {
                max-width: 250px;
                width: 250px;
                min-width: 250px;
              }
            }
            &:nth-child(2) {
              max-width: 250px;
              width: 250px;
              min-width: 250px;
            }
            &:nth-child(3) {
              max-width: 220px;
              width: 220px;
              min-width: 220px;
            }
            &:nth-child(4) {
              max-width: 160px;
              min-width: 160px;
            }
            &:nth-child(5) {
              min-width: 100px;
              padding: 15px 0px;
            }
          }
        }
        tbody {
          > tr:hover {
            --bs-table-accent-bg: $white !important;
            box-shadow: 0px 0px 8px -4px rgba($black, 0.1);
            background-color: #fff;
          }
          > tr:hover > * {
            --bs-table-accent-bg: $white !important;
          }
          tr {
            border-radius: 8px;
          }
          td {
            padding: 24px 24px;
            vertical-align: middle;
            &:nth-child(5) {
              padding: 24px 0;
            }
            a:hover {
              text-decoration: underline;
            }
            a,
            span {
              color: $primary;
              font-weight: 400;
              letter-spacing: 0.22px;
              text-decoration: none;
              @media (max-width: map-get($mediaSizes, 'maxxl')) {
                @include span_16();
              }
              @media (max-width: map-get($mediaSizes, 'xxl')) {
                font-size: 14px !important;
                line-height: 18px !important;
              }
            }
            &:first-child {
              border-radius: 8px 0 0 8px;
            }
            &:last-child {
              border-radius: 0 8px 8px 0;
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
  .detail-badge-left {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }
  .detail-badge-right {
    display: inline-block;
    vertical-align: middle;
    padding-left: 39px;
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      width: 500px;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      width: 381px;
    }
    @media (max-width: map-get($mediaSizes, 'lg')) {
      width: 50%;
    }
    @media (max-width: map-get($mediaSizes, 'md')) {
      width: 100%;
      padding-left: 0;
    }
  }
  &.detail-badge-all {
    max-width: 1068px;
    width: 100%;
    display: block;
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      max-width: 1500px;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      max-width: 1068px;
    }
    .alls {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 16px;
      column-gap: 21px;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: map-get($mediaSizes, 'md')) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 0px;
        column-gap: 0px;
      }
    }
  }
  .card {
    max-width: 342px;
    border-radius: 8px;
    border: 0;
    margin-bottom: 21px;
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      max-width: 445px;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      max-width: 342px;
    }
    @media (max-width: map-get($mediaSizes, 'md')) {
      max-width: 100%;
    }
    &.light-card {
      background-color: $light_blue1;
      .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .one {
          padding-right: 16px;
        }
      }
    }
    .card-body {
      padding: 25px 25px 25px 25px;
      .span-10 {
        margin-bottom: 8px;
        text-transform: uppercase;
      }
      p {
        @include span_16();
        color: $primary;
        margin-bottom: 0px;
        &.scroll {
          max-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;
          @include scroll_vertical();
        }
      }
      a.text {
        font-weight: 600 !important;
        color: $primary !important;
        margin-bottom: 0px;
        @include span_14();
        transition: 0.3s ease-in-out;
        &:hover {
          color: $pink !important;
          text-decoration: none;
        }
      }
      .img-data {
        text-align: center;
        img {
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            max-width: 395px !important;
            max-height: 220px !important;
            min-height: 220px !important;
          }
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            max-width: 100% !important;
          }
          @media (max-width: map-get($mediaSizes, 'xl')) {
            min-height: 100px !important;
          }
        }
      }
    }
  }
}

/*********************************
* Table 
**********************************/

.table-responsive {
  width: 100%;
  @include scroll_horizontal();
  table {
    width: 100%;
    thead {
      th {
        font-size: 10px;
        line-height: 12.55px;
        padding: 15px 24px;
        color: $light_blue;
        font-weight: 400;
        text-transform: uppercase;
        min-width: auto;
      }
    }
    tbody {
      > tr:hover {
        --bs-table-accent-bg: $white !important;
        box-shadow: 0px 0px 8px -4px rgba($black, 0.1);
        background-color: #fff;
      }
      > tr:hover > * {
        color: $primary;
        --bs-table-accent-bg: $white !important;
      }
      tr {
        border-radius: 8px;
      }
      td {
        font-size: 14px;
        line-height: 18px;
        color: $primary;
        font-weight: 400;
        letter-spacing: 0.22px;
        text-decoration: none;
        padding: 24px 24px;
        vertical-align: middle;

        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
          padding-right: 15px;
        }
      }
    }
  }
}
.checkbox {
  align-items: center;
  border: 1px solid #8b95b1;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 16px;
  justify-content: center;
  min-width: 16px;
  width: 16px;
}
