$primary: #23366c;
$primary_hover: #002aa3;
$primary_focus: #5f99ff;
$primary_blue: #0041d9;
$light_blue: #8b95b1;
$card_hover: #7a87a7;
$light_blue1: #e6edf9;
$white: #ffffff;
$black: #000000;
$color_f4f5: #f4f5f8;
$color_e7e7: #e7e7e7;
$body_color: #f7f8fc;
$placeholder_color: #c1c7d7;
$light_warning: #fff9e1;
$green: #4d9335;
$light_green: #8cbf59;
$light_success: #26d5ce;
$light_success_hover: #1eaaa4;
$select_hover: #e8eaf0;
$dark_layout: #20334e;
$pink: #fb306e;
$pink_hover: #c1043e;
$old_mint: #77c99c;
$disable_btn: #c1c7d7;
$error-color: #ff7959;
$gloabal_width: 1440px;
$big_gloabal_width: 1920px;

$mediaSizes: (
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1500px,
  'xxxl': 1950px,
  'maxxl': 10000px
);

@mixin span_16 {
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 18px;
    line-height: 28px;
  }
}

@mixin span_14 {
  color: $primary;
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 16px;
    line-height: 25px;
  }
}

@mixin linebreak2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

// ***** start - issuer card css *****
.issuer-card-data {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 38px;
  row-gap: 38px;
  @media (max-width: map-get($mediaSizes, 'xl')) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: map-get($mediaSizes, 'lg')) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: map-get($mediaSizes, 'md')) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px !important;
    row-gap: 20px !important;
  }
  .card {
    border: 0;
    border-radius: 8px;
    text-decoration: none;
    transition: 0.3s ease-in;
    &:hover {
      transition: max-height 0.5s ease-in, background-color 0.5s ease-in,
        background-color 0.5s ease-in;
      background-color: $card_hover !important;
      .card-body {
        .card-text-data {
          span,
          span.mix,
          h3,
          p {
            color: $white;
          }
          span.mix {
            .badge-award-counter-main {
              .badge-award-counter-inner {
                h3 {
                  span {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .card-body {
      padding: 20px 30px 25px;
      .card-text-data {
        text-align: center;
        .head-div {
          min-height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        h3 {
          font-weight: 700;
          color: $primary;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            @include span_16();
            font-weight: 700;
            margin-bottom: 0px;
            display: -webkit-box !important;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 5px;
          }
          span {
            padding-left: 5px;
          }
        }
        p {
          font-weight: 400;
          color: $primary;
          max-width: 184px;
          margin: 0 auto 16px;
          min-height: 36px;
          @include linebreak2();
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            font-size: 10px;
            line-height: 15px;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        span.mix {
          display: inline-block;
          font-weight: 400;
          color: $primary;
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            font-size: 12px;
            line-height: 15px;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            @include span_14();
          }
          h3 {
            font-size: 20px;
            line-height: 25.1px;
            font-weight: 700;
            margin-right: 2px;
            margin-bottom: 0;
            display: inline-block;
          }
          .badge-award-counter-main {
            display: flex;
            align-items: center;
            justify-content: center;
            .badge-award-counter-inner {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              &.first {
                margin-right: 10px;
              }
              span {
                font-weight: 400;
                color: $primary;
                margin: 0 auto 0;
                @media (max-width: map-get($mediaSizes, 'xxl')) {
                  font-size: 10px;
                  line-height: 15px;
                }
                @media (max-width: map-get($mediaSizes, 'maxxl')) {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
// ***** end - issuer card css *****
