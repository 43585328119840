[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dihkbo-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: '\e900';
}
.icon-see:before {
  content: '\e901';
}
.icon-dont-see:before {
  content: '\e902';
}
.icon-warning:before {
  content: '\e903';
}
.icon-close:before {
  content: '\e904';
}
.icon-home:before {
  content: '\e905';
}
.icon-user-management:before {
  content: '\e906';
}
.icon-chat-interaction:before {
  content: '\e907';
}
.icon-bot-training:before {
  content: '\e908';
}
.icon-settings:before {
  content: '\e909';
}
.icon-arrow-up:before {
  content: '\e90a';
}
.icon-arrow-down:before {
  content: '\e90b';
}
.icon-user:before {
  content: '\e90c';
}
.icon-tile:before {
  content: '\e90d';
}
.icon-send-message:before {
  content: '\e90e';
}
.icon-more:before {
  content: '\e90f';
}
.icon-plus:before {
  content: '\e910';
}
.icon-search:before {
  content: '\e911';
}
.icon-filters:before {
  content: '\e912';
}
.icon-edit:before {
  content: '\e913';
}
.icon-delete:before {
  content: '\e914';
}
.icon-arrow-back:before {
  content: '\e915';
}
.icon-notification:before {
  content: '\e916';
}
.icon-offers:before {
  content: '\e917';
}
.icon-play:before {
  content: '\e918';
}
.icon-pause:before {
  content: '\e919';
}
.icon-pdf:before {
  content: '\e91a';
}
.icon-h1:before {
  content: '\e91b';
}
.icon-h2:before {
  content: '\e91c';
}
.icon-p:before {
  content: '\e91d';
}
.icon-bold:before {
  content: '\e91e';
}
.icon-bullet-list:before {
  content: '\e91f';
}
.icon-calendar:before {
  content: '\e920';
}
.icon-date:before {
  content: '\e921';
}
.icon-location:before {
  content: '\e922';
}
.icon-suppliers:before {
  content: '\e923';
}
.icon-invoicing:before {
  content: '\e924';
}
.icon-archive:before {
  content: '\e925';
}
.icon-success:before {
  content: '\e926';
}
.icon-user-manual:before {
  content: '\e927';
}
.icon-list:before {
  content: '\e928';
}
.icon-xml-upload:before {
  content: '\e929';
}
.icon-chambers:before {
  content: '\e92a';
}
.icon-mapping:before {
  content: '\e92b';
}
.icon-new-imports:before {
  content: '\e92c';
}
.icon-double-check:before {
  content: '\e92d';
}
.icon-approve:before {
  content: '\e92e';
}
.icon-merge-release:before {
  content: '\e92f';
}
.icon-tagging:before {
  content: '\e930';
}
.icon-info-file:before {
  content: '\e931';
}
.icon-check:before {
  content: '\e932';
}
.icon-incoming-down:before {
  content: '\e933';
}
.icon-incoming-up:before {
  content: '\e934';
}
.icon-merge:before {
  content: '\e935';
}
.icon-unmerge:before {
  content: '\e936';
}
.icon-archived:before {
  content: '\e937';
}
.icon-less:before {
  content: '\e938';
}
.icon-ads:before {
  content: '\e939';
}
.icon-arrow-long-up:before {
  content: '\e93a';
}
.icon-arrow-long-down:before {
  content: '\e93b';
}
.icon-ihk-badges:before {
  content: '\e93c';
}
